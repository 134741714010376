
































































import { Group } from "@/types/group";
import Component from "vue-class-component";
import groupModule from "@/store/modules/groups";
import AvatarUpload from "@/components/utility/AvatarUpload.vue";
import userModule from "@/store/modules/user";
import { Dictionary } from "@/types/helpers";
import ConfirmModal from "@/components/utility/ConfirmModal.vue";
import { Ref, Vue } from "vue-property-decorator";
import { User } from "@/types/user";
import authModule from "@/store/modules/user";

@Component({
  components: {
    AvatarUpload,
    ConfirmModal,
  },
})
export default class GroupManage extends Vue {
  errors: Dictionary<string | string[]> = {};
  @Ref() readonly confirm!: ConfirmModal;

  get group(): Group | undefined {
    return JSON.parse(JSON.stringify(groupModule.group));
  }

  get user(): User | undefined {
    return authModule.user;
  }

  get uploadURL(): string {
    return process.env.VUE_APP_API_BASE_URL + "groups/" + this.group?.id;
  }

  get isAuthorGroup(): boolean {
    if (!userModule.user || !this.group) return false;
    return userModule.user.id == this.group.user_id;
  }

  handleUpload(group: Group): void {
    if (groupModule.group) {
      groupModule.group.picture = group.picture;
    }
  }

  async updateGroup(): Promise<void> {
    if (!this.group) return;

    try {
      await groupModule.updateGroup(this.group);
      this.$toast.success(this.$t("global.success").toString());
      this.errors = {};
    } catch (err) {
      this.errors = err.response.data.errors;
      this.$toast.error(this.$t("global.error-form").toString());
    }
  }

  async delGroup(): Promise<void> {
    const title = this.$t("group.dialog.confirm.title").toString();
    const message = this.$t("group.dialog.confirm.message").toString();
    const reply = await this.confirm.open(title, message, {});
    if (reply) {
      if (this.group) {
        try {
          await groupModule.removeGroup(this.group.id);
          this.$toast.success(this.$t("global.success").toString());
          this.$router.push({ name: "Home" });
        } catch (err) {
          this.$toast.success(this.$t("global.errors.unknown").toString());
        }
      }
    }
  }

  async leaveGroup(): Promise<void> {
    const title = this.$t("group.dialog.leave.title").toString();
    const message = this.$t("group.dialog.leave.message").toString();
    const reply = await this.confirm.open(title, message, {});
    if (reply) {
      if (!this.group) return;
      const group = this.group;
      try {
        this.$router.push({ name: "Home" });
        await groupModule.leave(group);
      } catch (err) {
        this.$toast.error(err.response.data.message);
      }
    }
  }
}
